.design {
  h1 {
    font-size: 60px;
  }


  .tariff-plans {
    margin: 70px 0;

    h2 {
      text-align: center;
    }


  }
}

.tariff-plans_items {
  display: flex;
  justify-content: space-between;
  margin: 140px 0;
  .tariff-plans_item {
    border: solid 1px #dddddd;
    position: relative;
    min-width: 200px;
    width: 320px;
    //background: #161718;
    //height: 600px;
//overflow-y: auto;
    h3 {
      text-align: center;
    }

    .tariff-top {
      padding: 20px;
      border-bottom: solid 1px #dddddd;
    }

    .tariff-middle {
      padding: 10px 10px 0 0;

      ul {
        list-style-type: revert;
      }

      .then-click {
        text-align: center;
      }
    }

    .tariff-bottom {
      text-align: center;
      padding: 20px;
      //position: absolute;
      bottom: 20px;
      left: 25px;

      button {
        font-family: "Cinzel-Bold";
        padding: 20px;
        cursor: pointer;

      }
    }
  }
  //.corporate-website{
  //  height: 510px;
  //}
}
@media (max-width: 1026px){
  .tariff-plans_items .tariff-plans_item{
    width: 290px;
  }
}
@media (max-width: 1026px){
  .tariff-plans_items .tariff-plans_item{
    width: 300px;
  }
}
@media (max-width: 945px) {
  .tariff-plans_items {
    flex-direction: column;
    align-items: center;

    .tariff-plans_item {
      margin-bottom: 20px;
      width: 480px;
      //height: 480px;
    }
  }
}
@media (max-width: 512px) {
  .tariff-plans_items {
    flex-direction: column;
    align-items: center;

    .tariff-plans_item {
      margin-bottom: 20px;
      width: 330px;
      //height: 680px;
    }

    .business{
      height: 560px;
    }

    .custom {
      height: 590px;
    }

    .wow {
      height: 410px;
    }
  }
}

.web-development {
  h1 {
    font-size: 60px;
  }
  @media (max-width: 638px){
    h1 {
      font-size: 40px;
      //margin: 50px 0 30px;
    }
  }

  .web-development_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .web-development_item {
      border: solid 1px #dddddd;
      width: 336px;
      margin: 15px 0;
      &:hover{
        box-shadow: 10px 10px 10px rgb(0 0 0 / 60%);
      }

      .web-development_item_title {
        display: flex;
        justify-content: space-between;
        padding: 23px;
        border-bottom: solid 1px #dddddd;
        font-size: 22px;
        //background: #363636;
      }

      .web-development_item_content {
        padding: 10px;
        img{
          width: 200px;
          margin: 10px 50px 10px;
        }
        p{
          margin: 10px 0 0;
        }
      }
    }
  }
  @media (max-width: 670px){
    .web-development_items{
      justify-content: center;
    }
  }
}