.bread-crumbs{
  margin: 50px 0;
  font-size: 19px;
  a:hover{
    color: #ee7028;
  }
  .link-right{
    margin-left: 6px;
  }
}


.corporate-website_top{
  width: 100%;
  display: flex;
  justify-content: space-between;
  .corporate-website_top_left{
    width: 70%;
    padding-right: 20px;
  }
}
@media (max-width: 800px){
  .corporate-website_top{
    width: 100%;
    flex-direction: column;
    .corporate-website_top_left{
      margin-top: 70px;
      width: 100%;
      order: 2;
      padding-right: 0;
      //text-align: center;
    }
  }
}