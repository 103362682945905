.scroll-to-top{
  background-color: #8d1219;
  border: solid 2px #c9b4a4;
  z-index: 11;
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  cursor: pointer;
  padding: 11px 6px;
  img{
    transform: rotate(270deg);
  }
}
@media (min-width: 2181px){
  .scroll-to-top{
    right: 15%;
  }
}