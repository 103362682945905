.page_content{
  padding: 160px 0 0;
  width: 100%;
}
.container{
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}
@media (max-width:500px){
  .page_content{
    padding: 90px 0 0;
  }
}