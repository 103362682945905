.header {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 9996;

  .background{
    position: fixed;
    width: 100%;
    background: #1f2324f0;
    //background: #000;
    transition-duration: .3s;
  }
  .container-header{
    position: relative;

    .nav-header{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .nav-header_left{
        display: flex;
        //align-items: center;
        width: 300px;
        justify-content: space-between;
        align-items: flex-end;
        @media (max-width: 500px){
          width: 275px;
          align-items: flex-end
        }
        .burger-left{
          padding: 42.25px 47.25px;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;



          }


        .nav-header_logo{
          padding: 0 50px 7px;
          img{
            width: 200px;
            height: auto;
          }
        }
        @media (max-width: 1500px){
          .burger-left {
            padding: 28px 32px 46px;
            height: 21px;
          }
          .nav-header_logo{
            padding: 0 28px 5px;
            img{
              width: 158px;
              height: auto;
            }
          }
        }
        @media (max-width: 1120px){
          .burger-left {
            border-right: none;
            border-bottom: none;
            padding: 5.5px 32px 33px;
          }
          .nav-header_logo{
            padding: 12px 0 5px;
            img{
              width: 165px;
            }
          }
        }

      }
    }

    .nav-header_right{
      .nav .nav-item {
        display: inline-block;
        font-family: "Trispace",sans-serif;
        font-size: 19px;
        padding: 38px 75px;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        color: #fff;
      }
      @media (max-width: 1500px){
        .nav .nav-item {
          padding: 22.5px 75px;
        }
      }
      @media (max-width: 1120px){
        .nav .nav-item {
          display: none;
        }
      }
    }
  }
}

.sidebar-toggle{
  display: flex;
  width: 26px;
  height: 21px;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  z-index: 999999!important;
  color: white;
  cursor: pointer;

  #sidebar-toggle span {
    display: block;
    position: absolute;
    z-index: 999999!important;
    height: 2px;
    width: 100%;
    background: #ffffff;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  #sidebar-toggle span:nth-child(1) {
    top: 0;
  }

  #sidebar-toggle span:nth-child(2) {
    top: 10px;
  }
  #sidebar-toggle span:nth-child(3) {
    top: 10px;
  }

  #sidebar-toggle span:nth-child(4) {
    top: 20px;
  }

  #sidebar-toggle.open span:nth-child(1) {
    top: 7px;
    width: 0%;
    left: 50%;
  }

  #sidebar-toggle.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  #sidebar-toggle.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  #sidebar-toggle.open span:nth-child(4) {
    top: 7px;
    width: 0%;
    left: 50%;
  }
}

.background_2 {
  position: fixed;
  z-index: 9995;
  top: 0;
  width: 100%;
  background: #000;
  height: 100%;

  .container {
    max-width: 100%;
    margin: 0 auto!important;

    .header-modal {
      position: relative;
      z-index: 99998;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 25px 10px;
      //background: linear-gradient(#1c1c1c,#000 80%);
      justify-content: center;
      overflow: hidden;
    }
    @media (max-width:500px){
      .header-modal{
        position: relative;
        z-index: 99998;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 90px 25px 10px;
        justify-content: flex-start;
        overflow: hidden;
      }

    }
  }
}

.nav-link_modal{
  font-size: 40px;
  height: 44px;
  margin-bottom: 25px;
  position: relative;
  span{
    font-family: 'Cinzel-Bold';
    padding: 10px;
  }
  .dropdown-link{
    position: absolute;
    right: -22px;
    bottom: -27px;
    padding: 10px 10px 0;
    z-index: 5;
    p{
      font-size: 25px;
      font-family: 'Cinzel-Bold';;
      margin: 0;
      &:hover{
        color: #433022;
      }
    }
  }
}
@media (max-width:1920px){
  .nav-link_modal{
    margin-bottom: 29px;
  }
}
.child{
  height: 150px;
  cursor: auto;

  .services_span{
    position: relative;
    //z-index: -1;
    &:hover{
      .dropdown-link > a{
        p{
          color: #d19469;
        }
      }
    }
  }
}
@media (max-width:500px){
  .nav-link_modal{
    height: 20px;
    font-size: 30px;
    .dropdown-link{
      position: absolute;
      right: -22px;
      bottom: -29px;
      padding: 10px 10px 0;
      z-index: 5;
      p{
        font-size: 20px;
        margin: 0;
      }
    }
  }
  .child{
    height: 105px;
    cursor: auto;
    .services_span{
      position: relative;
      //z-index: -1;
      &:hover{
        .dropdown-link > a{
          p{
            color: #d19469;
          }
        }
      }
    }
  }
}

.dropdown-link > a.active{
  p{
    color: #d19469;
  }
}
.nav-link_modal span{
  &:hover{
    color: #433022;
  }
}
.nav-item_modal > a.active{
  span {
    color: #855e43;
  }
}

.nav-modal_new{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 257px;
  height: auto;
  //margin-top: calc((100vh - 735px) / 2);
  justify-content: center;
  //.nav-item_modal{
  //  margin-top: 3px;
  //}
  a{
    color: white;
    &:hover{
      color: #433022;
    }
  }
}
.nav-item_modal > a.active span:hover{
  color: #433022;
}
.nav-item_phone, .nav-item_email{
  font-family: "Trispace", sans-serif;
  font-size: 19px;
  margin-bottom: 20px;
  padding-left: 10px;
}
.nav-item_telegram{
  padding-left: 50px;
  svg:hover{
    fill: #433022;
  }
}
