.home-top {
  position: relative;
  z-index: 2;
  height: calc(100vh - 160px);
  @media (max-width: 500px) {
    margin-top: 100px;
  }

  .home-header {
    background: none;
    z-index: 222;

    h2 {
      font-family: 'Cinzel-Bold';
      //font-family: 'Cormorant', serif;
      //font-family: "Anton", sans-serif;
      //font-family: 'Archivo Black', sans-serif;
      font-weight: bold;
      font-size: 80px;
      margin-top: 50px;
      margin-bottom: 90px;
      color: #000000;
      text-shadow: 1px 0 1px #fff, -1px 0 1px #fff, 0 1px 1px #fff, 0 -1px 1px #fff;
    }

    @media (min-width: 1500px) {
      h2 {
        font-size: 100px;
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
     //.development{
     //  line-height: 90%;
     //  font-family: 'Cinzel-Bold';
     //  font-weight: bold;
     //  font-size: 80px;
     //  margin-top: 50px;
     //  margin-bottom: 90px;
     //  color: #dedede;
     //  text-shadow: 1px 0 1px #000, -1px 0 1px #000, 0 1px 1px #000, 0 -1px 1px #000;
     //}
    // .commerce{
    //   font-family: 'Cinzel-Bold';
    //   font-weight: bold;
    //   font-size: 80px;
    //   margin-top: 50px;
    //   margin-bottom: 90px;
    //   color: #dedede;
    //   text-shadow: 1px 0 1px #000, -1px 0 1px #000, 0 1px 1px #000, 0 -1px 1px #000;
    // }
    // .blockchain{
    //   font-family: 'Cinzel-Bold';
    //   font-weight: bold;
    //   font-size: 80px;
    //   margin-top: 50px;
    //   margin-bottom: 60px;
    //   color: #dedede;
    //   text-shadow: 1px 0 1px #000, -1px 0 1px #000, 0 1px 1px #000, 0 -1px 1px #000;
    // }
    @media (max-width: 1500px) {
      .design, .development, .blockchain {
        font-size: 60px;
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
    @media (max-width: 788px) {
      .design {
        margin-top: -15px;
        font-size: 60px;
        margin-bottom: 30px;
      }
      .development {
        //line-height: normal;
        font-size: 60px;
        margin-bottom: 30px;
      }
      .commerce {
        font-size: 60px;
      }
      .blockchain {
        font-size: 60px;
      }
    }
    @media (max-width: 499px) {
      .design {
        margin-top: -15px;
        font-size: 42px;
        margin-bottom: 30px;
      }
      .development {
        //line-height: normal;
        font-size: 42px;
        margin-bottom: 30px;
      }
      .commerce {
        font-size: 42px;
        margin-bottom: 30px;
      }
      .blockchain {
        font-size: 42px;
      }
    }
  }
}

@media (min-width: 2000px) {
  .home-top {
    padding-top: 42px;
  }
}

// contact-with-us
.wrap-link {
  display: flex;
}

.contact-with-us {
  height: 83px;
  width: 250px;
  font-weight: 900;
  background: linear-gradient(90deg, #af6229 0%, #68112c 80%);
  padding: 28px;
  margin-top: 50px;
  cursor: pointer;
  border: solid 2px #dec5b5;
  text-align: center;
  font-size: 21px;

  h4 {
    margin: 0;
    font-size: 19px;
  }
}

.contact-with-us:hover {
  box-shadow: 2px 2px 12px #222222;
  background: linear-gradient(90deg, #723f18 0%, #4e0c21 80%);
  border: solid 2px #dddddd;
}

@media (max-width: 499px) {
  a .contact-with-us {
    cursor: pointer !important;

  }
}

// our services
.our-services {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  height: auto;
  //width: 90%;
  margin-right: 0;

  .our-services_title {
    font-size: 60px;
    margin: 150px 0 90px;
    color: #dedede;
    text-shadow: 1px 0 1px #000, -1px 0 1px #000, 0 1px 1px #000, 0 -1px 1px #000;
    font-family: "Trispace", sans-serif;
  }
}

@media (max-width: 699px) {
  .our-services {
    .our-services_title {
      font-size: 60px;
      margin: 50px 0 30px;
    }
  }
}

@media (max-width: 499px) {
  .our-services {
    .our-services_title {
      font-size: 50px;
      margin: 50px 0 30px;
    }
  }
  .about .about_title {
    font-size: 50px;
    margin: 50px 0 30px;
  }
}

// our-services_header
.our-services_header {
  display: flex;
  margin-bottom: 50px;

  p {
    font-family: "Trispace", sans-serif;
    font-size: 20px;
    padding-right: 10px;
  }
}

@media (max-width: 499px) {
  .our-services_header {
    flex-direction: column;
  }
}

// wrapper-our-services_content
.wrapper-our-services_content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: auto;


}

.our-services_content-items {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  //margin: 0 auto;
  .our-services_content-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 550px;
    border: solid 1px #fff;
    margin-top: 20px;
    &:hover{
      box-shadow: 10px 10px 10px rgb(0 0 0 / 60%);
    }
    .our-services_content-item-header {
      display: flex;
      justify-content: space-between;
      width: 549px;
      padding: 15px;
      border-bottom: solid 1px #fff;

      h3 {
        margin: 0;
      }

      //span{
      //  font-family: "Trispace", sans-serif;
      //}
    }

    .our-services_content-item-header h3 {
      height: auto;
      //font-size: 20px;
      color: white;
      font-family: "Trispace", sans-serif;
    }

    .our-services_content-item-content {
      padding: 15px;
      display: flex;
      flex-direction: column;
      img{
        width: 250px;
        margin: 0 auto 20px;
      }
      p {
        margin: 0;
        height: auto;
        font-size: 16px;
        font-family: "Trispace", sans-serif;
      }
    }

  }
  @media (max-width: 1200px) {
    .our-services_content-item {
      width: 380px;
      .our-services_content-item-header {
        width: 379px;
      }
    }
  }
  @media (max-width: 500px) {
    .our-services_content-item {
      width: 320px;

      .our-services_content-item-header {
        display: flex;
        justify-content: space-between;
        width: 320px;
        padding: 15px;
        border-bottom: solid 1px #fff;

        h3 {
          margin: 0;
        }

        //span{
        //  font-family: "Trispace", sans-serif;
        //}
      }

      .our-services_content-item-header h3 {
        height: auto;
        //font-size: 20px;
        color: white;
        font-family: "Trispace", sans-serif;
      }

      .our-services_content-item-content {
        padding: 15px;

        p {
          margin: 0;
          height: auto;
          font-size: 20px;
          font-family: "Trispace", sans-serif;
        }
      }

    }
  }
}

@media (max-width: 894px) {
  .wrapper-our-services_content {
    flex-direction: column;
    align-items: center;

  }
  .our-services_content-items {
    margin: 20px auto;
  }
}
.service{
  .our-services_content-item{
    width: 60%;
    @media (max-width: 650px){
      width: 100%;
    }
    a{
      .our-services_content-item-header{
        width: 100%;
      }
    }
  }
}

// about us
.about {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  height: auto;

  .about_title {
    font-size: 60px;
    font-family: "Trispace", sans-serif;
    margin: 150px 0 90px;
    color: #dedede;
    text-shadow: 1px 0 1px #000, -1px 0 1px #000, 0 1px 1px #000, 0 -1px 1px #000;
  }

  .about_content {

  }
}

@media (max-width: 699px) {
  .about .about_title {
    font-size: 29px;
    margin: 50px 0 30px;
  }
}

@media (max-width: 499px) {
  .about .about_title {
    font-size: 22px;
    margin: 50px 0 30px;
  }
}