

.my-form {
  //max-width: 900px;
  margin: auto;
  height: 77vh;
  z-index: 5;
  position: relative;
  img{
    margin-top: 50px;
    max-width: 60px;
    position: absolute;
    left: 260px;

  }
}

.form__wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 28px;
  //position: absolute;
  //top: 0;

  //background-image: url("../img/contact/bg-colibri.png");
  //z-index: 3;
  //position: absolute;
  //width: 900px;

  h3 {
    //text-align: center;
    display: inline-block;
  }

  .form__title {
    font-family: "Trispace", sans-serif;
    font-weight: 600;
    color: white;
    margin: 0;
    padding: 30px 0;
  }
}

@media (max-width: 700px) {
  .form__wrapper {
    margin-top: 0;
    //width: 100vw;
    padding-right: 20px;
  }
}

.form__description {
  padding: 0 0 20px;
  max-width: 60vw;
}

.fields {
  display: flex;
  flex-direction: column;
}

.field {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 75px;

  input, input:focus-within {
    box-sizing: border-box;
    border: solid 1px #454545;
    background: #f5f5f5;
    height: 40px;
    width: 30vw;
    //border-radius: 10px;
    padding: 10px 15px;
    color: black;
  }

  input,
  input:-webkit-autofill,
  textarea,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    //font-family: 'Hind', sans-serif;
    font-size: 17px;
    font-weight: 400;
    background-color: #e2d9f1;

    &, &:hover, &:focus, &:active {
      -webkit-box-shadow: 0 0 0px 1000px #e2d9f1 inset !important;
    }
  }

  @media screen and (max-width: 800px) {
    input, input:focus-within {
      width: 60vw;
    }
    .form__description {
      max-width: 60vw;
    }
  }

  @media screen and (max-width: 450px) {
    input, input:focus-within {
      width: 80vw;
    }
    .form__description {
      max-width: 90vw;
    }
  }

  p.errorMsg {
    font-family: "Archivo", sans-serif;
    //font-weight: 700;
    font-size: 17px;
    margin: 0;
    color: #95304e;
    display: inline-block;
  }
}

.field.field__button {

  button {
    height: 83px;
    width: 250px;
    font-weight: 900;
    background: linear-gradient(90deg, #af6229 0%, #68112c 80%);
    padding: 28px;
    margin-top: 50px;
    cursor: pointer;
    border: solid 2px #dec5b5;
    text-align: center;
    font-size: 21px;
    color: #dddddd;

    h4 {
      font-size: 19px;
    }
  }


}

.field.field__button > button:hover {
  box-shadow: 2px 2px 12px #222222;
  background: linear-gradient(90deg, #723f18 0%, #4e0c21 80%);
  border: solid 2px #dddddd;
}

@media screen and (max-width: 499px) {
  .field.field__button > button {
    cursor: pointer !important;
  }
}

//.field button {
//  width: auto;
//
//  border: solid 1px #454545;
//  background-color: #dadada;
//}
.message {
  height: 120px;

  textarea, textarea:focus-within {
    box-sizing: border-box;
    border: solid 1px #454545;
    background: #f5f5f5;
    height: 60px;
    //width: 40vw;
    color: #1c1b1b;
    //border-radius: 10px;
    padding: 10px 15px;
    resize: none;
    outline: none;
  }

  @media screen and (max-width: 800px) {
    textarea, textarea:focus-within {
      width: 70vw;
    }
    .form__description {
      width: 70vw;
    }
  }
  @media screen and (max-width: 450px) {
    textarea, textarea:focus-within {
      width: 90vw;
    }
  }
}

.hover {
  &:hover {
    box-shadow: 5px 5px 10px #6b6b6b;
  }
}

@media screen and (min-width: 800px) {
  .form__description {
    width: 40vw;
  }
}

@media screen and (max-width: 800px) {
  .form__description {
    width: 70vw;
  }
}

@media screen and (max-width: 450px) {
  .form__description {
    max-width: 90vw;
  }
}

.contact {
  height: auto;
  margin-top: 30px;

  .contact__map {
    max-width: 400px;
  }

  .display__none {
    position: absolute;
    bottom: 5000px;
    z-index: -200;
  }
}

.address {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  overflow-y: hidden;
  margin-bottom: 30px;

  h3, p {
    margin: 0;
    display: block;
    padding-rigth: 50px;
  }
}

.address__contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 30px;
}

.address__phone__email {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: #000;

    &:hover {
      color: #8b5fe3;
    }
  }
}

:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}

.success {
  position: relative;
  top: -116px;
  color: #30cf9a;
  //font-family: 'Cormorant', serif;
  font-size: 30px;
  font-weight: bold;
}