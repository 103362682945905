footer {
  margin: 150px 0 50px;
  padding-bottom: 20px;
}

.container-footer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e4d1bf;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-footer_links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    padding-bottom: 20px;
    h4 {
      margin: 5px 0;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  ul {
    padding: 0;
  }

  .container-footer_bottom {
    display: flex;
    align-items: center;
    .address {
      min-width: 180px;
      span {
        padding: 0 30px;

      }
      .right {
        border-left: solid 2px white;
      }
    }
  }
}
@media (max-width:1400px){
  footer {
    margin: 50px 0 0;
  }
  //.container-footer{
  //  border-left: none;
  //  border-bottom: none;
  //  border-right: none;
  //}
}
@media (max-width:790px){
  .container-footer{
    flex-direction: row;
    //padding: 20px 0;
    .container-footer_links{
      flex-direction: column;
      width: 50%;
    }
    .container-footer_bottom{
      //
      .address{
        display: flex;
        flex-direction: column;
        span {
          padding: 0 0 10px;

        }
        .right {
          border-left: none;
        }
      }

    }
  }
}
@media (max-width:515px){
  .container-footer{
    justify-content: space-between;
  }
  .container-footer_bottom{
    flex-direction: column;
  }
}