@font-face {
  font-family: 'Cinzel-Bold';
  font-weight: 900;
  src: local('Cinzel-Bold'), url(./fonts/Cinzel/Cinzel-Bold.ttf) format('opentype');
}
@import "base";
@import "layout";
@import "header";
@import "box-left";
@import "home";
@import "contact";
@import "footer";
@import "scroll_to_top";
@import "portfolio";
@import "services";
@import "services-links";
@import "cursor";