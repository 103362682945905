.portfolio-title{
  h1{
    font-size: 60px;
    margin-bottom: 50px;
  }
}
@media (max-width: 900px){
  .portfolio-title{
    h1{
      font-size: 29px;
    }
  }
}
.portfolio-items {
  margin-bottom: 50px;

  .portfolio-item {
    border: solid 1px #f5f5f5;
    //border-radius: 20px;
    padding: 15px 15px 30px;
    margin-bottom: 40px;
    box-shadow: 10px 10px 10px rgb(0, 0, 0, 0.6);
    //background: linear-gradient(#25282a 0%, #25282a 80%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .portfolio-item_info, .portfolio-item_nav {
      min-width: 120px;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        //color: #c9853d;
      }

      img {
        width: 701px;
        border: solid 1px #7c7777;
        //box-shadow: 10px 10px 10px #282727;
        box-shadow: 10px 10px 10px rgb(0, 0, 0, 0.6);
      }

      @media (max-width: 1200px) {
        img {
          width: 450px;
        }
      }
      @media (max-width: 500px) {
        img {
          width: 350px;
        }
      }
      @media (max-width: 400px) {
        img {
          width: 300px;
        }
      }
    }

    @media (max-width: 750px) {
      .portfolio-item_info, .portfolio-item_nav {
        display: none;
      }
      justify-content: center;
    }
  }
}

.portfolio-project {
  display: flex;
  justify-content: space-between;
  h1{
    font-size: 60px;
  }
  @media (max-width: 1030px){
      h1{
        font-size: 29px;
      }
  }
  .portfolio-item {
    img {
      width: 900px;
      margin: 50px auto;
    }
  }
  @media (max-width: 1030px) {
    .portfolio-item {
      img {
        width: 400px;
      }
    }
  }
  @media (max-width: 500px) {
    .portfolio-item {
      img {
        width: 274px;
      }
    }
  }
  .portfolio-link {
    padding-top: 32px;
    @media (max-width: 1030px){
      padding: 10px 10px 0;
    }
    .image-link {
      transform: rotate(320deg);
    }
  }
}