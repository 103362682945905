iframe {
  pointer-events: none;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  background: #1c1e20;
}

.modal{
  overflow: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}
a{
  text-decoration: none;
}
h2,h3,h4,h5,h6{
  color: #dddddd;
  font-family: 'Cinzel-Bold';
}
h1,p,span,a,li{
  color: #dddddd;
  font-family: "Trispace", sans-serif;
}
p,li{
  font-size: 20px;
}
ul {
  list-style-type: none;
}
.link-left{
  margin-left: 20px;
}

// ------------- start-project ---------------
.start-project {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 140px;
  border: solid 0.25px #dddddd;
  padding: 60px 20px;
  //background: #161718;
  .start-project_title {
    width: 50%;
  }

  .contact-with-us {
    margin: 0;
  }
}

@media (max-width: 605px) {
  .start-project {
    flex-direction: column;

    .start-project_title {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
// --------- our-technical-stack ---------
.our-technical-stack{
  margin-top: 100px;
  .our-technical-stack_top{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .our-technical-stack_top_title{
      width: 45%;
      font-size: 30px;
    }
    .our-technical-stack_top_description{
      width: 50%;
    }
  }
  @media (max-width: 800px){
    .our-technical-stack_top{
      flex-direction: column;
      .our-technical-stack_top_title{
        width: 100%;
        font-size: 30px;
      }
      .our-technical-stack_top_description{
        width: 100%;
      }
    }
  }
}

// --------- our-technical-stack_items ---------
.our-technical-stack_items{
  margin-top: 70px;
  border: solid 1px #dddddd;
  position: relative;

  .our-technical-stack_item{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: solid 1px #dddddd;
    //background: #161718;
    .title{
      width: 50%;
    }
    .description{
      width: 50%;
    }
  }

}
@media (max-width: 800px){
  .our-technical-stack_items{
    margin-top: 10px;
    border: solid 1px transparent;
    .our-technical-stack_item{
      margin-top: 10px;
      flex-direction: column;
      padding: 0;
      border: solid 1px #dddddd;
      .title{
        width: 100%;
        padding: 10px;
        border-bottom: solid 1px #dddddd;
      }
      .description{
        width: 100%;
        padding: 10px;
      }
    }
  }

}
// --------- stages-development ---------
.stages-development{
  margin: 140px 0;
  .ant-collapse>.ant-collapse-item>.ant-collapse-header,.ant-collapse{
    background: #1c1e20;
    color: #dec5b5;
  }

  .ant-collapse-content>.ant-collapse-content-box,.ant-collapse-content-hidden {
    background: #1c1e20;
    color: #dddddd;
  }
}

// --------- accordion-wrap ---------
.accordion-wrap{
  border: solid 1px #dddddd;

}

// ----------- our-services_content-img ------------
.our-services_content-img{
  position: relative;
  padding: 10px;

  img{
    max-width: 465px;
    width: auto;
  }
  @media (max-width:1200px){
    img{
      max-width: 460px;
    }
  }
  @media (max-width:530px){
    img{
      max-width: 460px;
    }
  }
  @media (max-width:499px){
    img{
      max-width: 334px;
    }
  }
  @media (max-width:370px){
    img{
      max-width: 300px;
    }
  }
  .slip{
    position: sticky;
    top: 110px;
  }
}

.our-services_content-img{
  img.corporate-website{
    width: 300px;
    height: auto;
    order: 1;
    margin: 0 auto;
  }
}
@media (max-width:800px){
  .our-services_content-img{
    display: flex;
    img.corporate-website{
      width: 300px;
      height: auto;
      order: 1;
      margin: 0 auto;
    }
  }
}
