.border-left{
  position: absolute;
  z-index: 2;
  left: 0;
  width: 121.5px;
  border-right: solid 1px white;
  border-bottom: solid 1px white;
  height: 100vh;
  //min-height: 800px;

  .border-left_container{
    position: relative;
    //width: 121.5px;
    //top: 102.5px;
    padding: 190px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //height: calc(100vh - 21px);
    height: 100vh;
    min-height: 650px;
    flex-direction: column;
    .border-left_text{
      position: relative;
      //top: 190px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      span{
        //font-family: 'Cormorant', serif;
        font-family: 'Cinzel-Bold';
        font-size: 21px;
      }
    }
    a{
      position: relative;

    }
  }

}
@media (max-width:1500px){
  .border-left{
    width: 91px;
  }
}
@media (max-width:1330px){
  .border-left{
    display: none;
  }
}